import ContentService from '@/services/isomorphic/deprecated/ContentService';
import { IContent as IDeprecatedContent, INavigationContent } from '@/services/models/Content';
import { IContent } from '@/services/isomorphic/ContentService';
import { IPage, PageType, Robots } from '@/services/models/Page';
import { JSONObject, Nullable } from '@/type-utils';
import { GetStaticPropsContext, PreviewData } from 'next';
import { ParsedUrlQuery } from 'querystring';

export { Robots };

/**
 * Page static props that are used to get content.
 */
interface IPageStaticProps {
  /** The context object for the static props. */
  ctx?: GetStaticPropsContext<ParsedUrlQuery, PreviewData>;
  /** The extra options to get the right static props. */
  options?: IPageStaticPropsOptions;
}

/**
 * Options object with additional possible arguments.
 */
export interface IPageStaticPropsOptions {
  /** Full page path as a string without the root. */
  path?: string;

  /** Tag for getting content by tag. */
  tag?: Nullable<string>;

  /** Is this for navigation only. */
  isNavigation?: boolean;

  /** The current page type, if missing is 'generic'. */
  pageType?: PageType;

  /**
   *  The canonical URL for the page.
   *  - Use `null` or `""` (empty string) to indicate that there is no canonical URL for the page.
   *  - Use `undefined` or leave blank to indicate that the page should use some sensible default.
   */
  canonicalURL?: Nullable<string>;

  /** The page meta data, these will be added to their corresponding meta tag. */
  pageMetaData?: {
    /** An array of possible values that the robots meta tag can have.
     * @see https://developers.google.com/search/docs/crawling-indexing/robots-meta-tag
     */
    robots: Array<Robots>;
    /**
     * The JSON-LD string to be added to the page. This is produced by the {@link StructuredDataService}.
     * Https://developers.google.com/search/docs/appearance/structured-data/intro-structured-data.
     */
    ldjson?: string;
  };

  /** Additional props to add. */
  additionalProps?: JSONObject;

  /**
   * The page title used in the <title> tag, this provides SEO keywords for a given page.
   */
  title?: string;

  /**
   * This is used as a 'description' in a meta tag in the head and is also used in google analytics.
   */
  description?: string;
}

/**
 * Data to be used as Static Props.
 */
export interface IPagePropsResult {
  /** Content data from CMS. */
  pageContent: Nullable<IDeprecatedContent>;

  /** Navigation data from CMS and global content. */
  navigationContent: INavigationContent;

  /** Content retrieved on the server side that doesn't conform to the above types. */
  content?: Nullable<IContent>;

  /** Current locale. */
  locale?: string;

  /** The page meta data model. */
  page?: IPage;

  /** Revalidation period in seconds. Next will attempt to re-generate the page.
   * When a request comes in at most once every x seconds.  */
  revalidate?: number;
}

/**
 * Defines the shape of the StaticProps object so that it can
 * be manipulated by other files.
 */
export interface IStaticPropsProps {
  /** StaticProps props object. */
  props: IPagePropsResult;

  /** Content revalidation period. */
  revalidate: number | boolean | undefined;
}

/**
 * Gets the static page given a certain segment path.
 * @param ctx - NEXT context object.
 * @param options - Options which includes the page slug.
 * @returns The static props for this page.
 */
export const getPageStaticProps = async ({
  ctx,
  options
}: IPageStaticProps): Promise<IStaticPropsProps> => {
  const path = options?.path ?? null;
  const tag = options?.tag ?? null;
  const pageType = options?.pageType ?? PageType.Generic;
  const canonicalURL = options?.canonicalURL;
  const title = options?.title;
  const description = options?.description;

  const content = await ContentService.getPageContent(path, tag);
  const additionalProps = options?.additionalProps;

  return {
    props: {
      pageContent: content.pageContent,
      navigationContent: content.navigationContent,
      page: {
        url: path ?? '',
        pageType,
        title: title ?? content.pageContent?.pageTitle ?? null,
        description:
          description ?? content.pageContent?.pageDescription ?? null,
        pageMetaData: {
          robots: options?.pageMetaData?.robots ?? []
        },
        ...(canonicalURL !== undefined && { canonicalURL }) // omit value if undefined
      },

      ...(additionalProps && { ...additionalProps })
    },
    revalidate: content.revalidate
  };
};
