'use client';

// Import libraries
import { IProduct } from '@/services/models/Product';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { msg } from '@/services/isomorphic/I18NService';
import { observer } from 'mobx-react-lite';
import { FunctionComponent } from 'react';

import { Nullable } from '@/type-utils';
import ProductTile from '../../ProductTile';

// Import styles and assets
import S from './styles.module.scss';
import { product_recommendations_title } from "@/lang/__generated__/ahnu/product_recommendations_title";

export interface IRecommendationsProps {
  /** The product to display. */
  products: Array<IProduct>;

  /** Should recommendations be a slide or a grid on mobile. */
  isSlideOnMobile?: boolean;

  /** The title of recommendation section. */
  title?: Nullable<string>;

  /** The variant of the product tile to display. */
  variant?: 'recommendation' | 'cart';
}

const DEFAULT_VARIANT = 'recommendation';

/** Component that displays product recommendations.  */
const Recommendations: FunctionComponent<IRecommendationsProps> = observer(
  ({ products, isSlideOnMobile, variant = DEFAULT_VARIANT, title }) => {
    return (
      <div className={classes(S.container, isSlideOnMobile ? S.slide : S.grid)}>
        {title && (
          <div className={S.title}>
            {title ?? msg(product_recommendations_title)}
          </div>
        )}
        <div className={S.tileContainer}>
          {products?.slice(0, 4).map((product, i) => {
            return (
              <ProductTile
                key={product.sku}
                product={product}
                variant={variant}
                isSlideTile={isSlideOnMobile}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

export default Recommendations;
